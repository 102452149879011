import React, { useState, useEffect } from 'react';
import _, { capitalize, ceil, isEmpty } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styles from './main.styles.js';
import Dialog from '@material-ui/core/Dialog';
import MyLocationIcon from '@material-ui/icons/MyLocation';

import brownCaneImage from '../images/Brown-cane.png';
import blackCaneImage from '../images/Black-cane.png';
import boyImage from '../images/boy.png';
import girlImage from '../images/girl.png';
import canCompanionLogo from '../images/canco.jpg';
import fallDetectionLogo from '../images/falldetection.png';
import whitelistLogo from '../images/whitelist.png';

import userService from '../user.service.js';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import EditDevice from '../EditDevice/EditDevice';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Accordion, AccordionSummary, Typography, ButtonGroup, Backdrop, Box, Modal, Fade } from '@material-ui/core';
import { Card, CardHeader, CardBody, Row } from 'reactstrap';
// core components
import { chartExample2 } from '../variable/charts';
// import FallReportChart from './components/FallReportChart.jsx';
import { toast } from 'react-toastify';
import CaregiverDP from './components/CaregiverDP.jsx';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SettingsIcon from '@mui/icons-material/Settings';

const useStyles = makeStyles(styles);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};
//console
function getUserInfo(userId) {
  let authData = userService.getAuthData();

  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${userId}/getUserInfo`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: authData.accessToken,
    },
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Couldn't get user");
      }

      return res.json();
    })
    .then((data) => {
      console.log('dataXXXXXXXX =====> ', data);
      return data;
    })
    .catch((err) => {
      console.log('errorXXXXXX =====> ', err);
      console.log(err);
    });
}

// device getDayActivities
function getDailyActivity(deviceId) {
  console.log('deviceId', deviceId);
  let authData = userService.getAuthData();

  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/devices/${deviceId}/getDayActivities?date=${new Date()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: authData.accessToken + '~12312312',
    },
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Couldn't get Daily Activity");
      }

      return res.json();
    })
    .then(({ data }) => data.time)
    .catch((err) => {
      console.log(err);
    });
}

function getLastGoal(userId) {
  let authData = userService.getAuthData();

  return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/users/${userId}/getGoal`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: authData.accessToken,
    },
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("Couldn't get Goal");
      }

      return res.json();
    })
    .then(({ data }) => {
      const lastGoal = (data.daily && data.daily[data.daily.length - 1]) || {};

      return lastGoal.value || 30;
    })
    .catch((err) => {
      console.log(err);
    });
}

function Device({ device, user, relation, refresh, lastLocation, userPortalSettings }) {
  function getDevice(deviceId) {
    return fetch(`${process.env.REACT_APP_EXPRESS_URL}/api/devices/${deviceId}/getDeviceInfo`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('auth'),
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Couldn't get device");
        }

        return res.json();
      })
      .then((data) => {
        console.log('response:', data);
        console.log(data);
        return setSupp(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const { t } = useTranslation();
  const [suppDeviceInfo, setSupp] = React.useState({});
  // const [gaitSpeedReports, setGaitSpeedReports] = React.useState([]);
  const [chartLabels, setChartLabels] = React.useState([]);
  const [tabs, setTabs] = React.useState({
    daily: true,
    weekly: false,
    monthly: false,
  });
  const [findCaneLoader, setFindCaneLoader] = React.useState(false);

  const handleTabClick = (filter) => {
    if (filter === 'daily') {
      setTabs({
        daily: true,
        weekly: false,
        monthly: false,
      });
    }
    if (filter === 'weekly') {
      setTabs({
        daily: false,
        weekly: true,
        monthly: false,
      });
    }
    if (filter === 'monthly') {
      setTabs({
        daily: false,
        weekly: false,
        monthly: true,
      });
    }
  };
  if (lastLocation) {
    device.lastLocation = lastLocation;
    console.log('last', device.lastLocation);
  }
  //let timezone = suppDeviceInfo?.timezone || -7;
  const classes = useStyles();
  const [isAccOpen, setAcc] = React.useState(false);
  const [state, setState] = useState({
    caregiverName: '',
    user: null,
    caregivers: [],
    mainCaregiver: null,
    goal: device.goal || '30',
    activity: '0',
    openEditDevice: false,
  });
  let caneImage = device.image;
  let caneImageClasses = classes.deviceImage;
  let caregiverImage = boyImage;
  let caregiverName =
    state.mainCaregiver && state.mainCaregiver.firstName
      ? `${state.mainCaregiver.firstName} ${state.mainCaregiver.lastName || ''}`
      : 'Caregiver';
  let defaultEmergencyPhoneNumber = '+16504698858';
  let emergencyPhoneNumber = null;
  let caneUserName =
    state.user && state.user.firstName ? `${state.user.firstName} ${state.user.lastName || ''}` : 'Cane User';
  let caneUserPhoneNumber = 'No Phone Number';
  const dailyGoalText = relation === 'my' ? t('your_daily_activity_goal') : t('daily_activities_goal');

  let caneUserImage = boyImage;

  if (state.user && state.user.imageUrl) {
    // caneUserImage = state.user.imageUrl;
  } else if (state.user && state.user.gender === 'female') {
    caneUserImage = girlImage;
  }

  if (state.caregiverName) {
    caregiverName = state.caregiverName;
  }

  if (relation === 'caregiver') {
    const wardDevice = _.find(user.caregiverTo, (item) => item.device === device.id);

    caneImage = wardDevice && wardDevice.imageUrl;
  }

  if (!caneImage) {
    caneImage = relation === 'my' ? brownCaneImage : blackCaneImage;
    caneImageClasses += ` ${classes.defaultImage}`;

    if (relation === 'my') {
      caneImageClasses += ` ${classes.tanBackground}`;
    } else {
      caneImageClasses += ` ${classes.blueBackground}`;
    }
  }

  let userImage = boyImage;

  if (!userImage) {
    userImage = (user.userInfo && user.userInfo.gender) === 'female' ? girlImage : boyImage;
  }

  function closeModal() {
    setState((prevState) => {
      return { ...prevState, openEditDevice: false, goal: device.goal };
    });
  }

  let phoneNumRegEx = new RegExp('^[+0-9]*$');

  if (phoneNumRegEx.test(user.emergencyContact)) {
    emergencyPhoneNumber = user.emergencyContact;
  } else {
    emergencyPhoneNumber = defaultEmergencyPhoneNumber;
  }
  let longitude = suppDeviceInfo?.results?.lastLocation?.lng;
  let latitude = suppDeviceInfo?.results?.lastLocation?.lat;
  let locationLastSeen = suppDeviceInfo?.results?.lastLocationSeen;

  const mapUrl = `https://www.google.com/maps?q=${suppDeviceInfo?.results?.lastLocation?.lat || -22.222221},${
    suppDeviceInfo?.results?.lastLocation?.lng || -22.222221
  }`;

  useEffect(() => {
    if (device.caregivers && device.caregivers.length) {
      const promisses = [];

      _.forEach(device.caregivers, (caregiver) => {
        promisses.push(getUserInfo(caregiver.user));
      });

      const newState = {};
      let caregivers = [];

      Promise.all(promisses).then((res) => {
        _.forEach(res, (caregiver) => {
          if (caregiver) {
            if (caregiver.userId === user.emergencyContact) {
              newState.mainCaregiver = caregiver;
              caregiver.main = true;
            }

            caregivers.push(caregiver);
          }
        });

        if (emergencyPhoneNumber !== defaultEmergencyPhoneNumber && !newState.mainCaregiver && !state.mainCaregiver) {
          const emergencyContactCaregiver = {
            firstName: 'Caregiver',
            lastName: '',
            imageUrl: boyImage,
            phoneNumber: emergencyPhoneNumber,
          };

          if ((user.emergencyContactInfo && user.emergencyContactInfo.phoneNumber) === emergencyPhoneNumber) {
            emergencyContactCaregiver.firstName = user.invitedCaregiverInfo.firstName || 'Caregiver';
            emergencyContactCaregiver.lastName = user.invitedCaregiverInfo.lastName || '';
            caregiverName = `${emergencyContactCaregiver.firstName} ${emergencyContactCaregiver.lastName}`;
            newState.caregiverName = caregiverName;
          }

          caregivers.push(emergencyContactCaregiver);
        }

        newState.caregivers = caregivers;

        setState((prevState) => {
          return { ...prevState, ...newState };
        });
      });
    } else if (emergencyPhoneNumber !== defaultEmergencyPhoneNumber) {
      const emergencyContactCaregiver = {
        firstName: 'Caregiver',
        lastName: '',
        imageUrl: boyImage,
        phoneNumber: emergencyPhoneNumber,
      };

      if ((user.invitedCaregiverInfo && user.invitedCaregiverInfo.phoneNumber) === emergencyPhoneNumber) {
        emergencyContactCaregiver.firstName = user.invitedCaregiverInfo.firstName || 'Caregiver';
        emergencyContactCaregiver.lastName = user.invitedCaregiverInfo.lastName || '';
        caregiverName = `${emergencyContactCaregiver.firstName} ${emergencyContactCaregiver.lastName}`;
      }

      setState((prevState) => {
        return {
          ...prevState,
          caregivers: [emergencyContactCaregiver],
          caregiverName,
        };
      });
    }

    const goalUserId = relation === 'my' ? user._id : device.user;

    getLastGoal(goalUserId).then((res) => {
      device.goal = res;
      setState((prevState) => {
        return { ...prevState, goal: res };
      });
    });

    if (Object.keys(device).length > 0) {
      getDailyActivity(device.id || device._id).then((res) => {
        setState((prevState) => {
          return { ...prevState, activity: res };
        });
      });
    }

    if (device.user) {
      getUserInfo(device.user).then((user) => {
        setState((prevState) => {
          return { ...prevState, user };
        });
      });
    }
  }, [user.emergencyContact, device]);
  function isFloat(n) {
    return n === +n && n !== (n | 0);
  }
  // const getGaitSpeed = async (deviceId) => {
  //   try {
  //     let result = await axios.get(`${process.env.REACT_APP_EXPRESS_URL}/api/gaitSpeed/${deviceId}`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         authorization: localStorage.getItem('auth'),
  //       },
  //     });

  //     return setGaitSpeed(result?.data?.results || 0);
  //   } catch (exception) {
  //     console.log('Exception', exception);

  //     setGaitSpeed('N/A');
  //   }
  // };

  // const [gaitSpeed, setGaitSpeed] = React.useState('N/A');

  const [dataSet, setDataSet] = React.useState([]);
  const [stepSet, setStepSet] = React.useState([]);

  const [activityGoal, setActivityGoal] = React.useState(0);
  const [openRewardPoints, setRewardPoints] = React.useState(false);

  React.useEffect(() => {
    if (suppDeviceInfo) {
      handleDataClick('daily');
      // handleGaitSpeed('daily');
    }
  }, [suppDeviceInfo]);

  const handleDataClick = async (value) => {
    try {
      if (isEmpty(suppDeviceInfo?.results?._id) || isEmpty(suppDeviceInfo?.results?.user)) return 0;
      let result = await axios.get(
        `${process.env.REACT_APP_EXPRESS_URL}/api/devices/${suppDeviceInfo?.results?._id}/todayWalkingActivity/${suppDeviceInfo?.results?.user}/${value}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: localStorage.getItem('auth'),
          },
        },
      );
      if (value === 'daily') {
        // let newVal = result?.data?.results?.activityMins.map((activity) => parseFloat(activity).toFixed(1));
        let newVal = result?.data?.results?.activityMins.map((activity) => activity);

        setActivityGoal(
          ceil(
            newVal.reduce((prev, next) => {
              // return parseFloat(prev) + parseFloat(next);
              return prev + next;
            }),
          ),
        );
      }
      setDataSet(
        result?.data?.results.activityMins.map((activity) => {
          if (activity) {
            return parseFloat(activity).toFixed(2);
          }
          return 0;
        }),
      );

      setStepSet(
        result?.data?.results.stepsArray.map((activity) => {
          if (activity) {
            return parseFloat(activity).toFixed(2);
          }
        }),
      );
      setChartLabels(result?.data?.results.labels);
    } catch (exception) {
      console.log('Exception', exception);
    }
    if (value === 'weekly') {
      handleTabClick('weekly');
    }
    if (value === 'daily') {
      handleTabClick('daily');
    }
    if (value === 'monthly') {
      handleTabClick('monthly');
    }
  };

  // const handleGaitSpeed = async (value) => {
  //   try {
  //     let result = await axios.post(
  //       `${process.env.REACT_APP_EXPRESS_URL}/api/gaitSpeed/reports`,
  //       {
  //         deviceId: suppDeviceInfo?.results?._id,
  //         userId: suppDeviceInfo?.results?.user,
  //         filter: value,
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           authorization: localStorage.getItem('auth'),
  //         },
  //       },
  //     );

  //     setGaitSpeedReports(result?.data?.results?.data?.data?.map((result) => parseFloat(result).toFixed(2)));
  //     // setChartLabels(result?.data?.results?.data?.labels);
  //   } catch (exception) {
  //     console.log(exception);
  //   }
  // };
  React.useEffect(() => {
    if (device) {
      // getGaitSpeed(device.id || device._id);
      getDevice(device.id || device._id);
    }
  }, [device]);
  const emergencyContactName = user?.emergencyContactInfo?.firstName
    ? user?.emergencyContactInfo?.firstName + ' ' + user?.emergencyContactInfo?.lastName
    : 'CAN Support';

  const handleFindMyCane = async () => {
    const userId = user?.id || '';
    const deviceId = device?.id || '';
    setFindCaneLoader(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_EXPRESS_URL}/api/users/${userId}/findMyCane/${deviceId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: localStorage.getItem('auth'),
          },
        },
      );
      toast(data?.results || 'Message dispatched!');
    } catch (exception) {
      console.log('Exception', exception);
      toast('Something went wrong!');
    }
    setFindCaneLoader(false);
  };

  const isDateInRange = (givenDate, daysBefore) => {
    return moment(givenDate).isBetween(moment().subtract(daysBefore || 7, 'days'), moment(), undefined, '[]');
  };

  return (
    <Grid style={{ width: '100%' }}>
      <div className={classes.deviceCard}>
        <Grid item style={{ backgroundImage: `url(${caneImage})` }} className={caneImageClasses} alt="device image" />
        <Grid container item direction="column" justify="center" alignItems="flex-start" className={classes.deviceInfo}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            className={classes.deviceInfoTop}
          >
            <div
              style={{ backgroundImage: `url(${caneImage})` }}
              className={`${caneImageClasses} ${classes.smallerDeviceImage}`}
              alt="device image"
            />
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
              className={classes.deviceInfoTopText}
            >
              <Grid item className={classes.deviceName}>
                {device.name}
                {/* {relation === 'my' && (
                  <div className={classes.yourDeviceMark}>
                    <div className={classes.greenDot}></div>
                    <span className={classes.yourDeviceMarkText}>{t('you')}</span>
                  </div>
                )} */}
              </Grid>
              <Grid item className={`${classes.deviceType} ${classes.grayText}`}>
                CAN Go
              </Grid>
            </Grid>
            <Grid item className={classes.deviceEditButtonContainer}>
              <Button
                variant="outlined"
                color="primary"
                disableRipple
                className={classes.deviceEditButton}
                onClick={() =>
                  setState((prevState) => {
                    return {
                      ...prevState,
                      openEditDevice: true,
                    };
                  })
                }
              >
                <SettingsIcon className={classes.editIcon} />
                <span className={classes.editText}>{t('settings')}</span>
              </Button>
            </Grid>
          </Grid>
          {relation === 'my' && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              className={classes.deviceCardUserInfo}
            >
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className={classes.deviceCardUserInfoCaregiver}
              >
                <Grid
                  item
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  className={classes.deviceCardCaregiver}
                >
                  <Grid item className={classes.deviceCardUserTopText}>
                    {t('your_caregivers')}
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.deviceCardUserInfoLeftPart}
                  >
                    <div className={classes.deviceCardUserImageContainer}>
                      {state?.mainCaregiver?.imageUrl ? (
                        <img
                          src={state.mainCaregiver.imageUrl}
                          alt="Caregiver"
                          className={classes.deviceCardUserImage}
                        />
                      ) : (
                        <CaregiverDP name={emergencyContactName} classes={classes} />
                      )}
                    </div>
                    <Grid
                      item
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                      className={classes.deviceCardUserInfoContainer}
                      xs={9}
                    >
                      <Grid item className={classes.deviceCardUserName}>
                        {capitalize(emergencyContactName)}
                      </Grid>
                      <Grid item className={`${classes.deviceCardUserDescription} ${classes.grayText}`}>
                        {t('emergency_contact')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.deviceCardPhoneNumberText}>
                  {user?.emergencyContactInfo?.phoneNumber || emergencyPhoneNumber}
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                className={`${classes.dailyGoal} ${classes.dailyGoalInner}`}
              >
                <Grid item className={`${classes.dailyGoalText} ${classes.grayText}`}>
                  {dailyGoalText}
                </Grid>
                <Grid item>
                  <div className={classes.activityProgress}>
                    <div className={classes.activityProgressIcon}></div>
                    <div className={classes.activityProgressText}>
                      <div className={`${classes.activityProgressNumbers} ${classes.grayText}`}>
                        <span style={{ color: 'black' }}>{activityGoal}</span>/{state.goal}
                      </div>
                      <div className={`${classes.grayText} ${classes.activityProgressMinutes}`}>{t('minutes')}</div>
                    </div>
                  </div>
                  <div className={classes.progressCircle}>
                    <CircularProgressbar
                      value={activityGoal}
                      maxValue={state.goal}
                      circleRatio={0.8}
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `#59B259`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'butt',
                          // Customize transition animation
                          transition: 'stroke-dashoffset 0.5s ease 0s',
                          // Rotate the path
                          transform: 'rotate(0.6turn)',
                          transformOrigin: 'center center',
                        },
                        trail: {
                          // Trail color
                          stroke: 'rgba(124,240,10,0)',
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'butt',
                          // Rotate the trail
                          transform: 'rotate(0.25turn)',
                          transformOrigin: 'center center',
                        },
                      }}
                    />
                  </div>
                  <div className={classes.configIconsDiv}>
                    {suppDeviceInfo?.results?.config?.CANassistant && (
                      <div style={{ marginInline: '5px' }} title="Can Companion">
                        <img src={canCompanionLogo} alt="can companion" className={classes.configIcon} />
                      </div>
                    )}
                    {suppDeviceInfo?.results?.config?.dropAlert && (
                      <div style={{ marginInline: '5px' }} title="Fall Detection">
                        <img src={fallDetectionLogo} alt="fall Detection" className={classes.configIcon} />
                      </div>
                    )}
                    {suppDeviceInfo?.results?.config?.whitelistEnable && (
                      <div style={{ marginInline: '5px' }} title="WhiteList">
                        <img src={whitelistLogo} alt="can companion" className={classes.configIcon} />
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          {relation === 'caregiver' && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              className={classes.deviceCardUserInfo}
            >
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                className={classes.deviceCardUserInfoCaregiver}
              >
                <Grid
                  item
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  className={`${classes.deviceCardCaregiver} ${classes.userInfoDeviceCard}`}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.deviceCardUserInfoLeftPart}
                  >
                    <div className={classes.deviceCardUserImageContainer}>
                      <img src={caneUserImage} alt="cane user" className={classes.deviceCardUserImage} />
                    </div>

                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                      className={`${classes.deviceCardUserInfoContainer} ${classes.deviceCardUserInfoContainerCaregiver}`}
                    >
                      <Grid item className={classes.deviceCardUserName}>
                        {caneUserName}
                      </Grid>
                      <Grid item className={`${classes.deviceCardUserDescription} ${classes.grayText}`}>
                        {t('cane_user')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  className={classes.deviceCardCaregiver}
                >
                  <Grid item className={classes.deviceCardUserTopText}>
                    {t('caregivers')}
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.deviceCardUserInfoLeftPart}
                  >
                    <div className={classes.deviceCardUserImageContainer}>
                      {/* <img src={userImage} alt="Caregiver" className={classes.deviceCardUserImage} /> */}
                      <CaregiverDP name={state?.user?.emergencyContactInfo?.firstName || 'CG'} classes={classes} />
                    </div>
                    <Grid
                      item
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                      className={classes.deviceCardUserInfoContainer}
                      xs={9}
                    >
                      <Grid item className={classes.deviceCardUserName}>
                        {state?.user?.emergencyContactInfo?.firstName && state?.user?.emergencyContactInfo?.lastName
                          ? `${state?.user?.emergencyContactInfo?.firstName} ${state?.user?.emergencyContactInfo?.lastName}`
                          : 'Caregiver'}
                      </Grid>
                      <Grid item className={`${classes.deviceCardUserDescription} ${classes.grayText}`}>
                        {t('emergency_contact')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.deviceCardPhoneNumberText}>
                  {state?.user?.emergencyContact || defaultEmergencyPhoneNumber}
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                className={`${classes.dailyGoal} ${classes.dailyGoalInner}`}
              >
                <Grid item className={`${classes.dailyGoalText} ${classes.grayText}`}>
                  {dailyGoalText}
                </Grid>
                <Grid item>
                  <div className={classes.activityProgress}>
                    <div className={classes.activityProgressIcon}></div>
                    <div className={classes.activityProgressText}>
                      <div className={`${classes.activityProgressNumbers} ${classes.grayText}`}>
                        <span style={{ color: 'black' }}>{activityGoal}</span>/{state.goal}
                      </div>
                      <div className={`${classes.grayText} ${classes.activityProgressMinutes}`}>{t('minutes')}</div>
                    </div>
                  </div>
                  <div className={classes.progressCircle}>
                    <CircularProgressbar
                      value={activityGoal}
                      maxValue={state.goal}
                      circleRatio={0.8}
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `#59B259`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'butt',
                          // Customize transition animation
                          transition: 'stroke-dashoffset 0.5s ease 0s',
                          // Rotate the path
                          transform: 'rotate(0.6turn)',
                          transformOrigin: 'center center',
                        },
                        trail: {
                          // Trail color
                          stroke: 'rgba(124,240,10,0)',
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'butt',
                          // Rotate the trail
                          transform: 'rotate(0.25turn)',
                          transformOrigin: 'center center',
                        },
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          {latitude &&
          longitude &&
          latitude != -22.222221 &&
          longitude != -22.222221 &&
          isDateInRange(locationLastSeen, 7) ? (
            <Grid
              xs={16}
              container
              style={{
                display: 'flex',
                direction: 'row',
                marginTop: '20px',
              }}
            >
              <Grid xs={12}>
                <span
                  style={{
                    border: '5px ',
                    font: 'Avenir',
                    width: '200px',
                    paddingLeft: '10px',
                    background: '#DDE1FF',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingRight: '10px',
                    color: '#2F3C98',
                  }}
                >
                  <a style={{ color: '#2F3C98' }} href={mapUrl} target="_blank">
                    <span style={{ width: '200px', fontSize: 16 }}>{t('latest_location')}</span>
                  </a>
                </span>
              </Grid>
            </Grid>
          ) : (
            <Grid
              xs={16}
              container
              style={{
                display: 'flex',
                direction: 'row',
                marginTop: '20px',
              }}
            >
              <Grid xs={12}>
                <span
                  style={{
                    border: '5px ',
                    font: 'Avenir',
                    width: '200px',
                    paddingLeft: '10px',
                    background: '#E0E0E0',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingRight: '10px',
                  }}
                >
                  <span style={{ width: '200px', fontSize: 16 }}>{t('no_location')}</span>
                </span>
              </Grid>
            </Grid>
          )}
          {/* Reward */}
          <Grid
            xs={16}
            container
            style={{
              display: 'flex',
              direction: 'row',
              marginTop: '20px',
            }}
          >
            <Grid
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                backgroundColor: '#DDE1FF',
                maxWidth: suppDeviceInfo?.results?.lastLocationSeen ? '58.33%' : '64%',
              }}
            >
              <span
                style={{
                  border: '5px ',
                  font: 'Avenir',
                  width: suppDeviceInfo?.results?.lastLocationSeen ? '165px' : '195px',
                  paddingLeft: '10px',
                  background: '#DDE1FF',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  paddingRight: '10px',
                  color: '#2F3C98',
                  flexDirection: 'row',
                }}
              >
                {t('reward_points')}{' '}
                <b
                  onClick={() => {
                    setRewardPoints(true);
                  }}
                  style={{
                    color: 'black',
                    cursor: 'pointer',
                  }}
                >
                  ⓘ
                </b>{' '}
                :
                <b
                  style={{
                    fontWeight: 'bold',
                    background: '#DDE1FF',
                  }}
                >
                  {' '}
                  {suppDeviceInfo?.results?.deviceInfo?.RewardsPoints || 0}
                </b>
              </span>
            </Grid>
          </Grid>
          <span style={{ fontSize: 'x-small' }}>{t('1000_points_free_subscription')}</span>
          <Grid item style={{ marginTop: '15px' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.deviceEditButton}
              onClick={handleFindMyCane}
              disabled={isEmpty(suppDeviceInfo?.results?.deviceInfo?.phoneno) || findCaneLoader}
              title="Find my cane"
            >
              {isEmpty(suppDeviceInfo?.results?.deviceInfo?.phoneno) ? (
                <span>{t('phone_not_assigned')}</span>
              ) : (
                <>
                  <MyLocationIcon className={classes.editIcon} />
                  <span>{findCaneLoader ? t('finding') : t('find_my_cane')}</span>
                </>
              )}
            </Button>
          </Grid>
        </Grid>

        <Grid item className={classes.dailyGoalContainer}>
          <Grid container direction="column" justify="flex-start" alignItems="center" className={classes.dailyGoal}>
            <Grid item className={`${classes.dailyGoalText} ${classes.grayText}`}>
              {dailyGoalText}
            </Grid>

            <Grid item>
              <div className={classes.activityProgress}>
                <div className={classes.activityProgressIcon}></div>
                <div className={classes.activityProgressText}>
                  <div className={`${classes.activityProgressNumbers} ${classes.grayText}`}>
                    <span style={{ color: 'black' }}>{activityGoal}</span>/{state.goal}
                  </div>
                  <div className={`${classes.grayText} ${classes.activityProgressMinutes}`}>{t('minutes')}</div>
                </div>
              </div>
              <div className={classes.progressCircle}>
                <CircularProgressbar
                  value={activityGoal}
                  maxValue={state.goal}
                  circleRatio={0.8}
                  styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke: `#59B259`,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'butt',
                      // Customize transition animation
                      transition: 'stroke-dashoffset 0.5s ease 0s',
                      // Rotate the path
                      transform: 'rotate(0.6turn)',
                      transformOrigin: 'center center',
                    },
                    trail: {
                      // Trail color
                      stroke: 'rgba(124,240,10,0)',
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'butt',
                      // Rotate the trail
                      transform: 'rotate(0.25turn)',
                      transformOrigin: 'center center',
                    },
                  }}
                />
              </div>
              <div className={classes.configIconsDiv}>
                {suppDeviceInfo?.results?.config?.CANassistant && (
                  <div style={{ marginInline: '5px' }} title="Can Companion">
                    <img src={canCompanionLogo} alt="can companion" className={classes.configIcon} />
                  </div>
                )}
                {suppDeviceInfo?.results?.config?.dropAlert && (
                  <div style={{ marginInline: '5px' }} title="Fall Detection">
                    <img src={fallDetectionLogo} alt="fall Detection" className={classes.configIcon} />
                  </div>
                )}
                {suppDeviceInfo?.results?.config?.whitelistEnable && (
                  <div style={{ marginInline: '5px' }} title="WhiteList">
                    <img src={whitelistLogo} alt="can companion" className={classes.configIcon} />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          className={classes.modal}
          open={state.openEditDevice}
          // fullWidth={true}
          maxWidth={false}
          // onClose={handleClose}
        >
          <EditDevice
            user={user}
            deviceOwner={state.user}
            device={device}
            deviceInfo={suppDeviceInfo}
            caregivers={state.caregivers}
            relation={relation}
            closeModal={closeModal}
            refresh={refresh}
            userPortalSettings={userPortalSettings}
          />
        </Dialog>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openRewardPoints}
        onClose={() => setRewardPoints(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openRewardPoints}>
          <Box sx={style} dir={localStorage.getItem('languageCode') === 'ar' ? 'rtl' : 'ltr'}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              <span
                style={{
                  color: '#2F3C98',
                }}
              >
                {t('reward_per_day')}
              </span>
            </Typography>
            <Typography style={{ paddingTop: '10px' }} id="transition-modal-description" sx={{ mt: 2 }}>
              <b style={{ color: '#2F3C98' }}>{t('5_points')}</b>: {t('5_points_para1')} <br></br>
              <b style={{ color: '#2F3C98' }}>{t('5_points')}</b>: {t('5_points_para2')}
              <br></br>
              <b style={{ color: '#2F3C98' }}>{t('10_points')}:</b> {t('10_points_para')}
              <br></br>
              <b style={{ color: '#2F3C98' }}>{t('5_points')}:</b> {t('5_points_para3')}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <Grid>
        <Accordion
          style={{
            backgroundColor: '#FCFAF8',
            color: 'black',
            marginBottom: '20px',
          }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => {
              setAcc(!isAccOpen);
            }}
          >
            <Typography>
              {t('show_activity_report')} {isAccOpen ? '▲' : '▼'}
            </Typography>
          </AccordionSummary>

          <Card style={{ backgroundColor: 'white' }}>
            <CardHeader
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="bg-transparent"
            >
              <Row className="align-items-center">
                <div className="col">
                  <h6 className="text-uppercase text-muted ls-1 mb-1">{t('activities_report')}</h6>
                  <ButtonGroup>
                    <Button
                      onClick={() => {
                        handleDataClick('daily');
                        // handleGaitSpeed('daily');
                      }}
                      style={
                        tabs.daily
                          ? {
                              backgroundColor: '#3f51b5',

                              color: 'white',
                            }
                          : {
                              backgroundColor: 'white',
                              borderColor: '#3f51b5',
                              color: '#3f51b5',
                            }
                      }
                      className={classes.deviceEditButton}
                    >
                      Today
                    </Button>
                    <Button
                      onClick={() => {
                        handleDataClick('weekly');
                        // handleGaitSpeed('weekly');
                      }}
                      style={
                        tabs.weekly
                          ? {
                              backgroundColor: '#3f51b5',

                              color: 'white',
                            }
                          : {
                              backgroundColor: 'white',
                              borderColor: '#3f51b5',
                              color: '#3f51b5',
                            }
                      }
                      className={classes.deviceEditButton}
                    >
                      7 days
                    </Button>
                    <Button
                      onClick={() => {
                        handleDataClick('monthly');
                        // handleGaitSpeed('monthly');
                      }}
                      style={
                        tabs.monthly
                          ? {
                              backgroundColor: '#3f51b5',

                              color: 'white',
                            }
                          : {
                              backgroundColor: 'white',
                              borderColor: '#3f51b5',
                              color: '#3f51b5',
                            }
                      }
                      className={classes.deviceEditButton}
                    >
                      30 days
                    </Button>
                  </ButtonGroup>
                  <h2 className="mb-0">{t('total_walking_activity')}</h2>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <Bar
                  data={{
                    labels: chartLabels,
                    datasets: [
                      {
                        label: t('walking_activities_minutes'),
                        data: dataSet,
                        maxBarThickness: 10,
                        backgroundColor: [
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                          '#3f51b5',
                        ],

                        borderColor: 'rgba(0,0,0,1)',
                      },
                    ],
                  }}
                  options={chartExample2.options}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <Bar
                  data={{
                    labels: chartLabels,
                    datasets: [
                      {
                        label: t('walking_activities_in_steps'),
                        data: stepSet,
                        maxBarThickness: 10,
                        backgroundColor: [
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                          'rgb(244, 148, 77)',
                        ],

                        borderColor: 'rgba(0,0,0,1)',
                      },
                    ],
                  }}
                  options={chartExample2.options2}
                />
              </div>

              {/* <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <Bar
                  data={{
                    labels: chartLabels,
                    datasets: [
                      {
                        label: t('walking_activities_gait_speed'),
                        data: gaitSpeedReports,
                        maxBarThickness: 10,
                        backgroundColor: [
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                          '#DDE1FF',
                        ],

                        borderColor: 'rgba(0,0,0,1)',
                      },
                    ],
                  }}
                  options={chartExample2.options3}
                />
              </div> */}
            </CardBody>
          </Card>
        </Accordion>
      </Grid>

      {/* <FallReportChart deviceId={device?.id} userId={user?.id} /> */}
    </Grid>
  );
}

export default Device;

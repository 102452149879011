import progressDashes from '../images/progress-dashes.svg';
import progressIcon from '../images/progress-icon.svg';
import colors from '../shared-colors';

export default (theme) => ({
  '@global': {
    body: {
      backgroundColor: colors.lightTan,
    },
  },

  userInfo: {
    // display: 'block',
  },
  userInfoContainer: {
    // minWidth: '300px',
    paddingTop: '32px',
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: colors.white,
    boxShadow: '2px 0px 8px rgba(228, 236, 239, 0.53)',
    borderRight: '0px',
    ['@media (min-width:1280px)']: {
      // eslint-disable-line no-useless-computed-key
      flexBasis: '300px',
    },
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      paddingTop: '40px',
      paddingLeft: '32px',
      paddingRight: '32px',
    },
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      paddingTop: '24px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  logoContainer: {
    height: '50px',
    width: '84px',
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '64px',
      height: '30px',
    },
  },
  logo: {
    // height: '100%',
    width: '100%',
  },
  userImageContainer: {
    marginTop: '40px',
    height: '148px',
    width: '148px',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '24px',
    },
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      height: '100px',
      width: '100px',
    },
  },
  userImage: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },

  mainUserInfoContainer: {
    marginTop: '25px',
    textAlign: 'center',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '16px',
    },
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '8px',
    },
  },
  userPhoneNumber: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  userEmail: {
    fontSize: '18px',
    marginTop: '8px',
    marginBottom: '16px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '4px',
      fontSize: '16px',
    },
  },
  showFullInfoButton: {
    fontWeight: '800',
    fontSize: '16px',
    color: '#2F3C98',
    cursor: 'pointer',
    marginBottom: '24px',
    ['@media (min-width:1280px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },

  additionalUserInfoContainer: {
    // width: '275px',
    marginTop: '40px',
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '0px',
    },
  },
  additionalUserInfoItem: {
    // maxWidth: '275px',
    marginTop: '15px',
  },
  additionalUserInfoItemKey: {
    fontSize: '14px',
  },
  additionalUserInfoItemValue: {
    fontSize: '18px',
  },

  userEditButtonContainer: {
    // width: '275px',
    width: '100%',
    marginTop: '40px',
  },
  userEditButton: {},
  editIcon: {
    width: '14px',
    height: '14px',
    paddingRight: '3px',
  },
  editText: {
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },

  userInfoFooter: {
    minWidth: '275px',
    marginTop: '100px',
    marginBottom: '20px',
    '@media (max-width:1280px)': {
      // eslint-disable-line no-useless-computed-key
      marginTop: '30px',
    },
  },
  textIcons: {
    color: 'lightgray',
    height: '20px',
    marginBottom: '-2px',
    marginRight: '5px',
    '&:hover': {
      color: colors.lightGray,
    },
  },
  supportButtonContainer: {
    paddingTop: '15px',
  },
  supportButton: {
    fontSize: '16px',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  logoutButton: {
    paddingTop: '15px',
    fontSize: '16px',
    // marginTop: '20px',
    // marginBottom: '20px',
    cursor: 'pointer',
  },

  deviceList: {
    // width: '1060px',
    // marginLeft: '366px',
    marginTop: '50px',
    // marginRight: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '32px',
    },
  },
  deviceListHeader: {
    maxWidth: '1030px',
    marginBottom: '30px',
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      marginBottom: '24px',
    },
  },
  deviceHeaderTextContainer: {
    // width: '500px',
  },
  deviceListGreetings: {
    textTransform: 'capitalize',
    fontSize: '32px',
    fontWeight: 'bold',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      fontSize: '24px',
    },
  },
  deviceListMessage: {
    fontSize: '18px',
    marginTop: '5px',
    color: colors.tanText,
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      fontSize: '16px',
    },
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '0px',
    },
  },
  addNewCaneButton: {
    // width: '180px',
    height: '50px',
  },
  addNewCaneText: {
    paddingLeft: '12px',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },

  deviceCard: {
    display: 'flex',
    minHeight: '265px',
    maxWidth: '1030px',
    marginBottom: '8px',
    borderRadius: '8px',
    ['@media (max-width:1280px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '960px',
    },
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '600px',
    },
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      width: 'auto',
    },
  },
  defaultImage: {
    paddingTop: '55px',
  },
  tanBackground: {
    backgroundColor: colors.tanShopify,
  },
  blueBackground: {
    backgroundColor: colors.blue,
  },
  deviceImage: {
    display: 'inline-block',
    // paddingTop: '55px',
    // width: '245px',
    borderRadius: '8px 0px 0px 8px',
    // backgroundColor: '#EEE2D7',
    backgroundSize: '100%',
    backgroundOrigin: 'content-box',
    backgroundRepeat: 'no-repeat',
    ['@media (min-width:1280px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '245px',
    },
    ['@media (max-width:1280px)']: {
      // eslint-disable-line no-useless-computed-key
      minWidth: '245px',
    },
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      minWidth: '100px',
      maxWidth: '30%',
      flexBasis: '30%',
    },
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  smallerDeviceImage: {
    display: 'none',
    ['@media (max-width:600px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'inline-block',
      minWidth: '40px',
      height: '28px',
      borderRadius: '20px',
      paddingTop: '12px',
      flexBasis: 'auto',
    },
  },
  deviceInfo: {
    boxShadow: '0px 2px 8px rgba(228, 236, 239, 0.53)',
    backgroundColor: colors.white,
    display: 'inline-block',
    borderRadius: '8px',
    padding: '16px',
    ['@media (min-width:1280px)']: {
      // eslint-disable-line no-useless-computed-key
      maxWidth: '453px',
      padding: '24px',
    },
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      paddingBottom: '32px',
    },
  },
  deviceInfoTop: {},
  deviceInfoTopText: {
    width: '65%',
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '50%',
    },
  },
  deviceName: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  deviceType: {
    fontSize: '14px',
  },
  deviceEditButton: {
    height: '32px',
    // width: '80px',
    // color: colors.navy,
  },
  deviceGaitSpeedContainer: {
    marginTop: '20px',
    display: 'flex',
    border: '5px solid red',
    padding: '5px',
  },

  deviceCardUserInfo: {
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  deviceCardUserInfoCaregiver: {
    marginTop: '28px',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '18px',
      maxWidth: '50%',
      flexBasis: '50%',
    },
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      maxWidth: '100%',
    },
  },
  deviceCardCaregiver: {
    ['@media (min-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      maxWidth: '60%',
      flexBasis: '60%',
    },
  },
  userInfoDeviceCard: {
    paddingBottom: '15px',
  },
  deviceCardUserTopText: {
    fontSize: '16px',
    // width: '100%',
    marginBottom: '10px',
  },
  greenDot: {
    display: 'inline-block',
    height: '4px',
    width: '4px',
    borderRadius: '50%',
    backgroundColor: 'green',
    marginLeft: '10px',
    marginRight: '5px',
    marginBottom: '3px',
  },
  yourDeviceMark: {
    display: 'inline-block',
  },
  yourDeviceMarkText: {
    color: colors.grayText,
    fontSize: '14px',
    fontWeight: 'normal',
  },
  deviceCardUserInfoLeftPart: {
    // width: '60%',
  },
  deviceCardUserImageContainer: {
    display: 'inline-block',
    marginRight: '8px',
  },
  deviceCardUserImage: {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
  },
  deviceCardUserInfoContainer: {
    // width: '70%',
  },
  deviceCardUserInfoContainerCaregiver: {
    width: '60%',
  },
  deviceCardUserName: {
    fontSize: '18px',
  },
  deviceCardUserDescription: {
    fontSize: '16px',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      fontSize: '14px',
    },
  },
  deviceCardPhoneNumberText: {
    fontSize: '18px',
    marginTop: '28px',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '3px',
      paddingLeft: '45px',
      width: '100%',
    },
  },

  dailyGoalContainer: {
    boxShadow: '0px 2px 8px rgba(228, 236, 239, 0.53)',
    backgroundColor: colors.white,
    width: '330px',
    borderRadius: '8px',
    marginLeft: 'auto',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  dailyGoalInner: {
    display: 'none',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'block',
      width: 'auto',
    },
  },
  dailyGoal: {
    paddingLeft: '20px',
    paddingRight: '20px',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      paddingLeft: '6px',
      paddingRight: '6px',
    },
  },
  dailyGoalText: {
    fontSize: '16px',
    marginTop: '24px',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '18px',
    },
  },
  activityProgress: {
    borderRadius: '50%',
    width: '170px',
    height: '170px',
    marginTop: '30px',
    // marginLeft: '90px',
    position: 'relative',
    backgroundImage: `url(${progressDashes})`,
    backgroundRepeat: 'no-repeat',
    ['@media (max-width:960px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '6px',
    },
    ['@media (max-width:470px)']: {
      // eslint-disable-line no-useless-computed-key
      marginTop: '16px',
    },
  },
  activityProgressIcon: {
    backgroundImage: `url(${progressIcon})`,
    width: '24px',
    height: '24px',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px',
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
  },
  activityProgressText: {
    marginTop: '65px',
    position: 'absolute',
    left: 0,
    right: 0,
  },
  activityProgressNumbers: {
    textAlign: 'center',
    fontSize: '32px',
  },
  activityProgressMinutes: {
    textAlign: 'center',
    fontSize: '16px',
  },
  progressCircle: {
    width: '165px',
    height: '165px',
    position: 'absolute',
    marginTop: '-170px',
    // marginLeft: '90px',
    borderRadius: '50%',
  },
  grayText: {
    color: colors.grayText,
  },
  missingDataModalCancelButton: {
    paddingInline: '40px',
    paddingBlock: '15px',
    marginTop: '20px',
    background: '#DC3444',
    border: 'none',
    borderRadius: '5px',
    marginRight: '5px',
    color: '#fff',
  },
  missingDataModalSubmitButton: {
    paddingInline: '40px',
    paddingBlock: '15px',
    marginTop: '20px',
    background: '#303F9F',
    border: 'none',
    borderRadius: '5px',
    color: '#fff',
  },
  missingDataError: {
    background: '#F8D7D9',
    borderRadius: '10px',
    paddingBlock: '5px',
    marginTop: '20px',
  },
  configIconsDiv: { display: 'flex', justifyContent: 'center', marginTop: '30px' },
  configIcon: { width: '40px', height: '40px' },
  phoneDiv: { display: 'flex', justifyContent: 'center' },
  phoneIcon: { cursor: 'pointer', height: '180px', width: 'auto' },
});
